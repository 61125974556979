import React from "react";

const TribeticSection1 = (props) => {
    return(
        <section className="section-1">
            <div className="container">
                <div className="title-and-description-block">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-20">
                            <h1 className="h1 section-title">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                            <div className="image-block">
                                <img
                                    src={props.image1x?.sourceUrl}
                                    srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                    width={props.image1x?.width}
                                    alt={props.image1x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TribeticSection1;